@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Ford Regular";
    src: url("./fonts/ford-antenna-cond-regular-52e873e1cda1a2ff677ce97e38481f292eac4c01b4f0684aca818e36e0c6047e.woff");
  }
  @font-face {
    font-family: "Ford Light";
    src: url("./fonts/antenna-cond-light-771cddfd050348ce52ab05ba12d86530776361e660967df14356a5bd41b00636.woff");
  }
  @font-face {
    font-family: "Ford Medium";
    src: url("./fonts/ford-antenna-cond-medium-d94df510bf2ac861daef5ca3ad7f1936dbfde3cd746fd3e667e7b85fd9fb65ea.woff");
  }

  @font-face {
    font-family: "Ford Bold";
    src: url("https://ford.bg/media_assets/9408b472-7d50-4ef3-a3e6-5750b06583b4")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Ford Black";
    src: url("./fonts/ford-antenna-cond-black.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  [type="text"],
  [type="url"],
  [type="tel"],
  [type="textarea"],
  [type="datetime-local"],
  select,
  [type="email"] {
    @apply w-full border-gray-300 rounded-lg shadow-sm focus:border-fordBlue focus:ring-fordBlue placeholder:text-slate-400;
  }

  [type="checkbox"] {
    @apply border-gray-300 rounded shadow-sm focus:border-fordBlue focus:ring-fordBlue checked:bg-fordBlue placeholder:text-slate-400;
  }

  select option {
    @apply text-slate-400;
  }
}

@layer components {
  .btn-primary {
    @apply px-8 py-2 text-white border rounded-full border-fordBlue bg-fordBlue;
  }
  .btn-secondary {
    @apply px-8 py-2 bg-white border rounded-full text-fordBlue border-fordBlue;
  }
}

html {
  scroll-behavior: smooth;
}

::file-selector-button {
  /* background-color:  ;
  color: red; */
  @apply px-8 bg-white border shadow-none border-fordBlue text-fordBlue;
}

/* LOGO LOADER */
#logo path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 1s forwards ease-in-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.logo-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
